<template>
  <div class="page">
    <!-- <div class="home-icon" @click.stop="goBack()">
      <img src="~@/assets/ic_back_28.png" alt />
    </div> -->
    <!-- <div class="collect-icon" @click="backHome()">
      <img src="~@/assets/icon_shangpin_home@2x.png" alt />
    </div> -->
    <div class="pop" v-if="popShow">
      <div class="pop-alert">
        <span>企业码：</span><yd-input border type="text" v-model="code" />
        <p>
          <button class="isTrue" @click="getPage">确定</button>
          <button class="isFasle" @click="close">取消</button>
        </p>
      </div>
    </div>
    <div v-if="!popShow">
      <img
        class="beforeZlBackground"
        v-if="config.backgroundImg && config.backgroundImg[0]"
        :src="config.backgroundImg[0].url"
      />
      <p
        @click="zlClick"
        class="spotFabulous"
        :style="{
          top: config.top + '%',
          color: config.brforespotFabulousTextColor
        }"
      >
        <img
          v-if="(config.spotFabulous && config.spotFabulous[0] && !is_part) || this.item_id != 0"
          style="width:100%"
          :src="config.spotFabulous[0].url"
        />
        <span
          class="afterspotFabulousText"
          v-if="(config.spotFabulous && config.spotFabulous[0] && !is_part) || this.item_id != 0"
          :style="{
            'font-size': config.spotFabulousTextFontsize + 'px',
            color: config.brforespotFabulousTextColor,
            width: config.spotFabulousWidth + '%',
            float: 'left',
            'margin-left': config.spotFabulousTextLeft + '%',
            height: config.beforespotFabulousTextHight + '%'
          }"
        >
          {{ config.brforespotFabulousText }}
        </span>

        <img
          v-if="config.spotFabulousEnd && config.spotFabulousEnd[0] && is_part && this.item_id == 0"
          style="width:100%"
          :src="config.spotFabulousEnd[0].url"
        />
        <span
          class="afterspotFabulousText"
          v-if="config.spotFabulousEnd && config.spotFabulousEnd[0] && is_part && this.item_id == 0"
          :style="{
            'font-size': config.spotFabulousTextFontsize + 'px',
            color: config.aftrerspotFabulousTextColor,
            width: config.afterSpotFabulousWidth + '%',
            float: 'left',
            'margin-left': config.afterSpotFabulousTextLeft + '%',
            height: config.aftrerspotFabulousTextHight + '%'
          }"
        >
          {{ config.aftrerspotFabulousText }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { queryActByCode, partActivity } from "@/services/index";
import { customAccount } from "../../utils/custom";
import { getStore } from "@/utils/common";
// import HeadNav from "@/components/common/HeadNav"
export default {
  name: "ExchangeRules",
  data() {
    return {
      content: "", // 兑换规则
      ischeck: true,
      clickisTrueType: true,
      isfirst: false,
      retries: 0,
      activetyId: "",
      activetyCode: "",
      popShow: true,
      is_part: 0,
      ranking: 0,
      code: "",
      top: "100%",
      config: {},
      aftrerspotFabulousTextHight: 100, //点赞后文字显示区域高度
      beforespotFabulousTextHight: 100, //点赞后文字显示区域高度
      item_id: 0
    };
  },
  async created() {
    await this.clickSucss();
    // this.config = JSON.parse(
    //   '{"backgroundImg":[{"name":"Uploads/image/2022-03/20220328102852_f95113f5dd8b2d23ca19a0d575abe8ab.jpeg","url":"https://yiqitong.oss-cn-hangzhou.aliyuncs.com/Uploads/image/2022-03/20220328102852_f95113f5dd8b2d23ca19a0d575abe8ab.jpeg","uid":1648434532939,"status":"success"}],"spotFabulous":[{"name":"Uploads/image/2022-03/20220328173903_1.png","url":"https://yiqitong.oss-cn-hangzhou.aliyuncs.com/Uploads/image/2022-03/20220328173903_1.png","uid":1648460343470,"status":"success"}],"spotFabulousEnd":[{"name":"Uploads/image/2022-03/20220328103113_710x194.png","url":"https://yiqitong.oss-cn-hangzhou.aliyuncs.com/Uploads/image/2022-03/20220328103113_710x194.png","uid":1648434674123,"status":"success"}],"brforespotFabulousText":"大嘴猴萨达大大撒大大大大大多大大大大","aftrerspotFabulousText":"没嘴猴拉搜大技能你妈说大口大口流量卡扩大到啦","spotFabulousTextFontsize":"20","aftrerspotFabulousTextColor":"#12A0CB","brforespotFabulousTextColor":"#EE0F0F","spotFabulousWidth":"50","afterSpotFabulousWidth":"40","spotFabulousTextLeft":25,"afterSpotFabulousTextLeft":30,"aftrerspotFabulousTextTop":0,"beforespotFabulousTextTop":0,"aftrerspotFabulousTextHight":100,"beforespotFabulousTextHight":100,"top":60}'
    // );
  },
  mounted() {},
  methods: {
    close() {
      this.$router.go(-1);
    },
    async zlClick() {
      if (this.is_part && this.item_id !== 0) {
        this.$router.push({
          name: "goods-detail",
          query: {
            item_id: this.item_id,
            activity_id: this.activetyId
          }
      });
        return false;
      }
      let res = await partActivity({
        activity_id: this.activetyId,
        code: this.activetyCode
      });
      if (res.code == 0) {
        this.$dialog.toast({
          mes: "助力成功",
          icon: "success"
        });
        if (this.item_id !== 0) {
          this.$router.push({
            name: "goods-detail",
            query: {
              item_id: this.item_id,
              activity_id: this.activetyId
            }
        });
          return false;
        }
        this.is_part = 1;
        this.ranking = res.data.ranking;
        console.log(this.ranking, "this.ranking");
        this.config.aftrerspotFabulousText = this.config.aftrerspotFabulousText.replace(
          "##",
          this.ranking
        );
      }
    },
    async getPage() {
      if (this.code == "") {
        this.$dialog.toast({
          mes: "请填写企业code码！"
        });
        return false;
      }
      let res = await queryActByCode({ code: this.code });
      if (res.code == 0) {
        this.config = JSON.parse(res.data.config);
        this.$nextTick(() => {
          document.title = res.data.title;
        });
        this.activetyId = res.data.id;
        this.item_id = res.data.item_id;
        this.activetyCode = res.data.code;
        this.is_part = res.data.is_part;
        this.ranking = res.data.ranking;

        // let reg =new RegExp('##','g')
        console.log(this.config, "this.ranking");
        if (this.is_part) {
          this.config.aftrerspotFabulousText = this.config.aftrerspotFabulousText.replace(
            "##",
            this.ranking
          );
        }
        this.popShow = false;
      } else {
        this.$dialog.toast({
          mes: res.msg
        });
      }
    },
    async clickisTrue() {
      //   if (this.ischeck) {
      //     let result = await zHbocClick();
      //     this.ischeck = false;
      //     if (result.code == 0) {
      //       await this.clickSucss();
      //       this.clickisTrueType = false;
      //       this.ischeck = true;
      //     } else {
      //       this.ischeck = true;
      //     }
      //   }
    },
    async clickSucss() {
      this.retries++;
      if (this.retries > 10) {
        return;
      }
      //   try {
      //     let result = await zHbocClickInfo();
      //     if (result.code == 0) {
      //       if (result.data.click_sort == 0) {
      //         this.clickisTrueType = true;
      //       } else {
      //         this.clickisTrueType = false;
      //       }
      //       this.number = result.data.click_sort;
      //       this.isfirst = true;
      //       return false;
      //     } else {
      //       this.clickSucss();
      //     }
      //   } catch (error) {
      //     this.clickSucss();
      //   }
    },
    customAccount,
    goBack() {
      this.$router.go(-1);
    },
    backHome() {
      this.$router.replace({
        path: "/home"
      });
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";
/deep/.yd-dialog-black-mask .yd-dialog-white-mask {
  z-index: 9999999 !important;
}
.clickisTrue {
  width: 54%;
  position: absolute;
  z-index: 999;
  margin-left: 23%;
  bottom: 13%;
}
.spotFabulous {
  position: absolute;
  z-index: 1000;
  left: 0;
  width: 100%;
  height: auto;
  // display:flex;
}
.afterspotFabulousText {
  /* position: absolute;
    width: 100%;
    text-align: center;
    z-index: 999999;
    left: 0; */
  /* line-height: 5; */
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1000;
  display: flex;
  left: 0;
  height: 100%;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  font-size: 16px;
  word-break: break-all;
  line-height: 1.3;
  bottom: 0;
}
.pop {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  .pop-alert {
    width: 80%;
    height: 30%;
    position: absolute;
    left: 10%;
    margin-top: 47%;
    border-radius: 10px;
    background: #eee;
    .yd-input {
      position: absolute;
      border: 1px solid #000;
      width: 60%;
      height: 40px;
      right: 5%;
      margin-top: 22%;
      font-size: 20px;
      padding-left: 10px;
    }
    .isTrue {
      position: absolute;
      width: 50%;
      height: 50px;
      border: 0;
      left: 0;
      font-size: 22px;
      color: #fff;
      background-color: #1989fa;
      border-radius: 0px 0px 0px 10px;
      bottom: 0;
    }
    .isFasle {
      position: absolute;
      width: 50%;
      height: 50px;
      right: 0;
      font-size: 22px;
      color: #fff;
      border: 0;
      background-color: #f00;
      bottom: 0;
      border-radius: 0px 0px 10px 0px;
    }
    span {
      float: left;
      font-size: 20px;
      margin-left: 30px;
      margin-top: 25%;
      color: #333;
    }
    input {
    }
  }
}

.clickSucss {
  width: 76%;
  position: absolute;
  height: 15%;
  margin-left: 12%;
  background-image: url("../../assets/WechatIMG14.png");
  background-size: 100% 100%;
  bottom: 10%;
  p {
    width: 80%;
    margin-left: 10%;
    position: absolute;
    bottom: 11%;
    font-size: 0.3rem;
    line-height: 1.5;
    font-weight: bold;
    text-align: center;
    color: #7b2900;
  }
}
.beforeZlBackground {
  width: 100%;
  position: absolute;
}
.beforeZlXs {
  width: 100%;
  position: absolute;
}
.text1 {
  width: 100%;
  //   position: absolute;
  left: 0;
  margin-top: 5%;
}
.bottom1 {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
}
.page {
  width: 100%;
  height: auto;
  .lastBottom {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 0.2rem;
    color: #000;
    font-weight: 400;
    background-color: #fff;
    letter-spacing: 2px;
  }
  //   background: url("../../assets/zhZlbackground.png") repeat-y;
  //   background-size: 100% 100%;
  //   padding: 0.32rem;
  box-sizing: border-box;
  .content {
    .sc(@fontsize-large, @color-dark-grey);
    line-height: 0.44rem;
    margin-top: 0.7rem;
  }
  .bgimg {
    width: 100%;
    top: 0;
    position: absolute;
  }
  .home-icon,
  .collect-icon {
    width: 0.56rem;
    height: 0.56rem;
    border-radius: 0.56rem;
    position: absolute;
    z-index: 110;

    img {
      width: 0.56rem;
      height: 0.56rem;
    }
  }

  .home-icon {
    left: 0.3rem;
    top: 0.4rem;
  }

  .collect-icon {
    right: 0.3rem;
    top: 0.4rem;
  }
}
</style>
