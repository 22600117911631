var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page" }, [
    _vm.popShow
      ? _c("div", { staticClass: "pop" }, [
          _c(
            "div",
            { staticClass: "pop-alert" },
            [
              _c("span", [_vm._v("企业码：")]),
              _c("yd-input", {
                attrs: { border: "", type: "text" },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
              _c("p", [
                _c(
                  "button",
                  { staticClass: "isTrue", on: { click: _vm.getPage } },
                  [_vm._v("确定")]
                ),
                _c(
                  "button",
                  { staticClass: "isFasle", on: { click: _vm.close } },
                  [_vm._v("取消")]
                ),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.popShow
      ? _c("div", [
          _vm.config.backgroundImg && _vm.config.backgroundImg[0]
            ? _c("img", {
                staticClass: "beforeZlBackground",
                attrs: { src: _vm.config.backgroundImg[0].url },
              })
            : _vm._e(),
          _c(
            "p",
            {
              staticClass: "spotFabulous",
              style: {
                top: _vm.config.top + "%",
                color: _vm.config.brforespotFabulousTextColor,
              },
              on: { click: _vm.zlClick },
            },
            [
              (_vm.config.spotFabulous &&
                _vm.config.spotFabulous[0] &&
                !_vm.is_part) ||
              this.item_id != 0
                ? _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.config.spotFabulous[0].url },
                  })
                : _vm._e(),
              (_vm.config.spotFabulous &&
                _vm.config.spotFabulous[0] &&
                !_vm.is_part) ||
              this.item_id != 0
                ? _c(
                    "span",
                    {
                      staticClass: "afterspotFabulousText",
                      style: {
                        "font-size": _vm.config.spotFabulousTextFontsize + "px",
                        color: _vm.config.brforespotFabulousTextColor,
                        width: _vm.config.spotFabulousWidth + "%",
                        float: "left",
                        "margin-left": _vm.config.spotFabulousTextLeft + "%",
                        height: _vm.config.beforespotFabulousTextHight + "%",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.config.brforespotFabulousText) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.config.spotFabulousEnd &&
              _vm.config.spotFabulousEnd[0] &&
              _vm.is_part &&
              this.item_id == 0
                ? _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: _vm.config.spotFabulousEnd[0].url },
                  })
                : _vm._e(),
              _vm.config.spotFabulousEnd &&
              _vm.config.spotFabulousEnd[0] &&
              _vm.is_part &&
              this.item_id == 0
                ? _c(
                    "span",
                    {
                      staticClass: "afterspotFabulousText",
                      style: {
                        "font-size": _vm.config.spotFabulousTextFontsize + "px",
                        color: _vm.config.aftrerspotFabulousTextColor,
                        width: _vm.config.afterSpotFabulousWidth + "%",
                        float: "left",
                        "margin-left":
                          _vm.config.afterSpotFabulousTextLeft + "%",
                        height: _vm.config.aftrerspotFabulousTextHight + "%",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.config.aftrerspotFabulousText) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }